import { Network, Alchemy } from "alchemy-sdk";

// Holds instances used for fetching general data about the user
// Does not include contract specifics
const settingsPolygon = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.MATIC_MAINNET, // Replace with your network.
};

const settingsMumbai = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.MATIC_MUMBAI, // Replace with your network.
};

const AlchemyPolygon = new Alchemy(settingsPolygon);
const AlchemyMumbai = new Alchemy(settingsMumbai);

export { AlchemyPolygon, AlchemyMumbai };
