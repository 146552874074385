import { FC, lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "./modules/Login/UserContext";

export interface IRoute {
  name: string;
  path: string;
  component: FC;
  index?: boolean;
  description?: string;
  isPrivate?: boolean;
  isExact?: boolean;
}

export const ROUTES = {
  ADMIN: "/dashobard",
  AUTHENTICATION_URL: "/auth",
  COLLECTION_URL: "/collection",
  HOME_URL: "/",
  SETTINGS: "/settings",
  VERY_BIG_TALKS_URL: "/talks",
};

export const publicRoutes: IRoute[] = [
  {
    component: lazy(() => import("./pages/Landing")),
    name: "Landing",
    path: ROUTES.AUTHENTICATION_URL,
  },
];

export const privateRoutes: IRoute[] = [
  {
    component: lazy(() => import("./pages/Collection")),
    name: "Collection",
    path: ROUTES.COLLECTION_URL,
  },
  {
    component: lazy(() => import("./pages/VeryBigNetwork")),
    name: "Home",
    path: ROUTES.HOME_URL,
  },
  {
    component: lazy(() => import("./pages/VeryBigTalks")),
    name: "Very Big Tlaks",
    path: ROUTES.VERY_BIG_TALKS_URL,
  },
  {
    component: lazy(() => import("./pages/Landing")),
    name: "Landing",
    path: ROUTES.HOME_URL,
  },
];

export const PrivateRoute = ({ children }: { children: any }) => {
  const { state } = useUserContext();
  if (!state.role) {
    return <Navigate to={ROUTES.AUTHENTICATION_URL} replace />;
  }

  return children ? children : <Outlet />;
};
