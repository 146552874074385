import { AlchemyPolygon } from "./Alchemy";
import { OwnedNft } from "alchemy-sdk";

// This expands the logic of Alchemy

export async function getUserAccessTokens(address: string) {
  const accessTokens = await AlchemyPolygon.nft
    .getNftsForOwner(address)
    .then((response) => {
      const { ownedNfts }: { ownedNfts: OwnedNft[] } = response;
      return ownedNfts.filter((nft: OwnedNft) => {
        return (
          nft.contract.address.toLocaleLowerCase() ===
          process.env.REACT_APP_VBT_NETWORK_CONTRACT_ADDRESS.toLocaleLowerCase()
        );
      });
    });
  return accessTokens;
}

export async function getUserOwnedNfts(address: string) {
  const userOwnedNfts: OwnedNft[] = await AlchemyPolygon.nft
    .getNftsForOwner(address, { tokenUriTimeoutInMs: 50000 })
    .then((response) => {
      const { ownedNfts }: { ownedNfts: OwnedNft[] } = response;
      return ownedNfts;
    });

  return userOwnedNfts;
}
