import { Box, Card, Typography } from "@mui/material";
import { FC } from "react";
import { Button } from "../Button";

interface LogoutWarningProps {
  handleAccept: () => void;
  handleDecline: () => void;
}

const LogoutWarning: FC<LogoutWarningProps> = ({
  handleAccept,
  handleDecline,
}) => {
  return (
    <Card>
      <Box
        display="flex"
        paddingX={5}
        paddingY={3}
        flexDirection="column"
        gap={3}
      >
        <Typography color="primary" align="center" variant="h6">
          Warning!
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="subtitle2">
            You will be logged out, continue?
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={handleAccept}>
              Accept
            </Button>
            <Button variant="text" sx={{ paddingX: 2 }} onClick={handleDecline}>
              Decline
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default LogoutWarning;
