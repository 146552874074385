import { Suspense, StrictMode } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { ROUTES, publicRoutes, privateRoutes, PrivateRoute } from "./routes";
import { QueryClientProvider, QueryClient } from "react-query";
import ThemeProvider from "./theme/ThemeProvider";
import SnackbarProvider from "./modules/SnackBar/SnackbarProvider";
import { UserContextProvider } from "./modules/Login/UserContext";
import Navigation from "./components/Navigation/Navigation";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App() {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <SnackbarProvider>
            <UserContextProvider>
              <BrowserRouter>
                <Navigation />
                <Routes>
                  {privateRoutes.map((route) => (
                    <Route
                      element={
                        <PrivateRoute>
                          <Suspense fallback={<CircularProgress />}>
                            <route.component />
                          </Suspense>
                        </PrivateRoute>
                      }
                      path={route.path}
                      key={route.path}
                    />
                  ))}
                  {publicRoutes.map((route) => (
                    <Route
                      element={
                        <Suspense>
                          <route.component />
                        </Suspense>
                      }
                      path={route.path}
                      key={route.path}
                    />
                  ))}
                  <Route
                    path="*"
                    element={<Navigate to={ROUTES.HOME_URL} replace />}
                  />
                </Routes>
              </BrowserRouter>
            </UserContextProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}
