import useActiveLink from "../../utilities/hooks/useActiveLink";
import { FC, ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "../Button";
import { ButtonProps, useTheme } from "@mui/material";

export interface NavigationItemProps extends ButtonProps {
  path?: string;
  icon?: ReactNode;
}

const NavigationItem: FC<NavigationItemProps> = ({ path, ...props }) => {
  const { active, isExternalLink } = useActiveLink(path);
  const theme = useTheme();

  return (
    <Button
      LinkComponent={path ? RouterLink : undefined}
      //   @ts-ignore TODO: handle this type error
      to={path}
      //   @ts-ignore TODO: handle this type error
      target={isExternalLink ? "_blank" : undefined}
      rel={isExternalLink ? "noopener" : undefined}
      variant="text"
      color={active ? "secondary" : "inherit"}
      size={props.icon ? "large" : undefined}
      sx={{ paddingX: theme.spacing(2) }}
      {...props}
    />
  );
};

export default NavigationItem;
