import { FC } from "react";
import {
  ToggleButton as MUIToggleButton,
  ToggleButtonProps,
} from "@mui/material";

const ToggleButton: FC<ToggleButtonProps> = (props) => (
  <MUIToggleButton {...props} />
);

export default ToggleButton;
