import { Theme } from "@mui/material/styles";
import Button from "./Button";
import Card from "./Card";
import LoadingButton from "./LoadingButton";
import ToggleButton from "./ToggleButton";
import Typography from "./Typography";
import Input from "./Input";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    Card(theme),
    LoadingButton(theme),
    Input(theme),
    ToggleButton(theme),
    Typography(theme)
  );
}
