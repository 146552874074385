import { useMemo, useState } from "react";
import { useUserContext } from "../../modules/Login/UserContext";
import { Dialog, Box, Toolbar, useTheme } from "@mui/material";
import Iconify from "../Iconify/Iconify";
import { Button } from "../Button";
import LogoutWarning from "./LogoutWarning";
import NavigationItem, { NavigationItemProps } from "./NavigationItem";
import { ROUTES } from "../../routes";

const Navigation = () => {
  const theme = useTheme();
  const { state, setState } = useUserContext();
  const { role } = state;

  const NAVIGATIONS = useMemo(() => {
    const COLLECTION_NAVIGATION = {
      path: ROUTES.COLLECTION_URL,
      children: "Collection",
    };

    const VERY_BIG_NETWORK_NAVIGATION = {
      path: ROUTES.HOME_URL,
      children: "Home",
    };

    const VERY_BIG_TALKS_NAVIGATION = {
      path: ROUTES.VERY_BIG_TALKS_URL,
      children: "Very Big Talks",
    };

    const VERY_BIG_THINGS_NAVIGATION = {
      path: "https://verybigthings.com/",
      children: "Very Big Things",
    };

    const MEMBER_NAVIGATION_ITEMS: NavigationItemProps[] = [
      VERY_BIG_NETWORK_NAVIGATION,
      COLLECTION_NAVIGATION,
      VERY_BIG_TALKS_NAVIGATION,
      VERY_BIG_THINGS_NAVIGATION,
    ];

    const GUEST_NAVIGATION_ITEMS: NavigationItemProps[] = [
      VERY_BIG_NETWORK_NAVIGATION,
      COLLECTION_NAVIGATION,
      VERY_BIG_THINGS_NAVIGATION,
    ];
    return {
      VBT_CREW: MEMBER_NAVIGATION_ITEMS,
      VBT_GUEST: GUEST_NAVIGATION_ITEMS,
    };
  }, []);

  //   ----------------------------------------------------------

  const navigationItems = useMemo(() => {
    const items = NAVIGATIONS[role];

    return items?.map((item: NavigationItemProps, index: number) => (
      <NavigationItem key={index} {...item} />
    ));
  }, [role, NAVIGATIONS]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    role && (
      <Box width="100%" position="fixed" display="flex" justifyContent="center">
        <Box maxWidth="1440px">
          <Box
            sx={{
              paddingX: 4,
              borderRadius: "0 0 16px 16px",
              backgroundColor: "rgba(0, 0, 0, 0.65)",
            }}
          >
            <Toolbar sx={{ display: "sticky", zIndex: theme.zIndex.appBar }}>
              {navigationItems}
              <Button
                color="primary"
                icon={<Iconify icon="il:lock" />}
                sx={{ marginLeft: 10 }}
                onClick={handleOpen}
              />
            </Toolbar>
            <Dialog
              open={open}
              PaperProps={{
                sx: { backgroundColor: "transparent", borderRadius: 2 },
              }}
            >
              {
                <LogoutWarning
                  handleDecline={handleClose}
                  handleAccept={() => {
                    setState({
                      ...state,
                      address: undefined,
                      role: undefined,
                      accessTokens: undefined,
                    });
                    setOpen(false);
                  }}
                />
              }
            </Dialog>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default Navigation;
