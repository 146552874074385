import { FC, ReactNode } from "react";
import {
  IconButton,
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from "@mui/material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

interface ButtonProps extends MUIButtonProps {
  loadingProps?: LoadingButtonProps;
  icon?: ReactNode;
}

const Button: FC<ButtonProps> = ({ loadingProps, icon, ...props }) => {
  return loadingProps ? (
    <LoadingButton {...props} {...loadingProps} />
  ) : icon ? (
    <IconButton {...props}>{icon}</IconButton>
  ) : (
    <MUIButton {...props} />
  );
};

export default Button;
