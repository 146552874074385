import { createTheme, ThemeOptions } from "@mui/material/styles";
import palette from "./options/palette";
import typography from "./options/typography";
import customShadows from "./options/customShadows";
import componentsOverride from "./overrides";

const themeOptions: ThemeOptions = {
  palette: palette,
  typography,
  shape: { borderRadius: 8 },
  customShadows: customShadows,
};

const theme = createTheme(themeOptions);

theme.components = componentsOverride(theme);

export default theme;
