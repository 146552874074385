import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OwnedNft } from "alchemy-sdk";
import { getUserAccessTokens } from "../../utilities/wallet/Tokens";

interface User {
  address?: string;
  accessTokens?: OwnedNft[];
  role?: string;
}

interface UserContextState {
  state: User;
  setState: Dispatch<SetStateAction<User>>;
  loading: boolean;
}

interface OnboardingcontextProps {
  children: ReactNode;
}

const initialData: User = {
  address: undefined,
  accessTokens: [],
};
// ------------------------------------

export const UserContext = createContext<UserContextState | null>(null);

export const useUserContext = (): UserContextState => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      "useUserContext must be used within an UserContextProvider"
    );
  }
  return context;
};

export const UserContextProvider: FC<OnboardingcontextProps> = ({
  children,
}) => {
  const [userData, setUserData] = useState<User>(initialData);
  const [loading, setLoading] = useState<boolean>(false);

  const { address } = userData;

  // When MetamaskWallet address changes, update user context
  useEffect(() => {
    const handleAccountsChanged = async (accounts: string[]) => {
      setLoading(true);
      if (accounts.length === 0) {
        console.log("Please connect to MetaMask.");
      } else {
        const userAddress = accounts[0];
        setUserData({ address: userAddress, accessTokens: [] });
      }
      setLoading(false);
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, []);

  // When user address changes, update Access Tokens and roles for that User
  useEffect(() => {
    const handleGetUserAccessTokens = async () => {
      setLoading(true);
      const accessTokens: OwnedNft[] =
        address && (await getUserAccessTokens(address));
      setUserData({
        address: address,
        accessTokens: accessTokens,
        role: undefined,
      });
      setLoading(false);
    };
    handleGetUserAccessTokens();
  }, [address]);

  const memoizedValue = useMemo(
    () => ({ state: userData, setState: setUserData, loading }),
    [userData, loading]
  );

  return (
    <UserContext.Provider value={memoizedValue}>
      {children}
    </UserContext.Provider>
  );
};
export const useOnboarding = () => useContext(UserContext);
